<template>
  <ChallengeForm
    v-if="state.challengeUser"
    @close="handleChallengeUserClose"
  />
  <div
    class="user-account-page"
  >
    <div
      v-if="state.loading"
      class="preloader"
    >
      Loading...
    </div>
    <div
      v-else-if="userInfo"
    >
      <PremiumWidget />
      <div
        class="user-account-page__header"
      >
        <UserSummaryWidget
          :user="userInfo"
        />
      </div>
      <div
        class="user-account-page__info"
      >

        <a-collapse
          v-model="state.activeKey"
          :bordered="false"
          expand-icon-position="end"
        >
          <a-collapse-panel
            :header="ringInfoTitle"
          >
            <RingInfoWidget
              :user="userInfo"
            />
          </a-collapse-panel>
          <a-collapse-panel
            header="Активные цели-челленджи"
          >
            <ActiveChallengesWidget
              :user="userInfo"
            />
          </a-collapse-panel>
          <a-collapse-panel
            header="Турниры и события"
          >
            <EventWidget />
          </a-collapse-panel>
          <a-collapse-panel
            header="Статистика фрилайна"
          >
            <FreelineStatisticsWidget
              :user="userInfo"
              :archive-challenge-list="archiveChallengeList"
            />
          </a-collapse-panel>
          <a-collapse-panel
            header="Чат для отчётов"
          >
            <RatingClubWidget
              :user="userInfo"
            />
          </a-collapse-panel>
          <a-collapse-panel
            header="Позвать друга"
          >
            <FriendsWidget
              :user="userInfo"
              @challenge="handleChallengeClick"
            />
          </a-collapse-panel>
          <a-collapse-panel
            header="Остров сокровищ"
          >
            <TreasureIsland
              :user="userInfo"
            />
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
>

import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
} from 'vue';
import ServiceContainerInterface
  from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import ClubCaseModel from '@/entities/CollapseCaseGenerator/ClubCaseModel';
import ChallengeForm from '@/app/Component/ChallengeForm.vue';
import PremiumWidget from '@/widgets/PremiumWidget/PremiumWidget.vue';
import UserSummaryWidget from '@/widgets/UserSummaryWidget/UserSummaryWidget.vue';
import RingInfoWidget from '@/widgets/RingInfoWidget/RingInfoWidget.vue';
import ActiveChallengesWidget from '@/widgets/ActiveChallengesWidget/ActiveChallengesWidget.vue';
import FreelineStatisticsWidget
  from '@/widgets/FreelineStatisticsWidget/FreelineStatisticsWidget.vue';
import RatingClubWidget from '@/widgets/RatingClubWidget/RatingClubWidget.vue';
import FriendsWidget from '@/widgets/FriendsWidget/FriendsWidget.vue';
import TreasureIsland from '@/widgets/TreasureIsland/TreasureIsland.vue';
import {
  CollapsePanel,
  Collapse,
} from 'ant-design-vue';
import ChallengeHelper from '@/shared/Helpers/ChallengeHelper';
import ChallengeResponseModel from '@/shared/Api/Model/Challenges/ChallengeResponseModel';
import TournamentModel from '@/entities/Tournament/TournamentModel';
import EventWidget from '@/widgets/EventWidget/EventWidget.vue';

interface State {
  loading: boolean;
  challengeUser: boolean;
  activeKey: number | null;
  tournamentList: Array<TournamentModel>;
  availableForRegistrationTournamentList: Array<TournamentModel>;
  tournamentLoaded: boolean;
}

export default defineComponent({
  name: 'UserAccountPage',
  components: {
    EventWidget,
    ACollapse: Collapse,
    ACollapsePanel: CollapsePanel,
    TreasureIsland,
    FriendsWidget,
    RatingClubWidget,
    FreelineStatisticsWidget,
    ActiveChallengesWidget,
    RingInfoWidget,
    UserSummaryWidget,
    PremiumWidget,
    ChallengeForm,
  },
  setup() {
    const state = reactive<State>({
      loading: true,
      challengeUser: false,
      activeKey: null,
      tournamentList: [],
      availableForRegistrationTournamentList: [],
      tournamentLoaded: false,
    });
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const {
      apiService,
    } = serviceContainer;

    const userInfo = computed<UserInfoResponse>(
      () => serviceContainer.store.getters.getUser,
    );

    const ringInfoTitle = computed(() => {
      if (userInfo.value?.ring) {
        return 'Как повысить перстень?';
      }

      return 'Как получить перстень?';
    });

    async function updateChallengeList() {
      if (!serviceContainer) {
        return;
      }

      await serviceContainer.store.dispatch('updateChallengeList');

      state.loading = false;
    }

    let intervalId: number;

    onMounted(async () => {
      await updateChallengeList();

      intervalId = setInterval(() => {
        serviceContainer.store.dispatch('updateUser');
      }, 5000);
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    function handleChallengeClick() {
      state.challengeUser = true;
    }

    function handleChallengeUserClose() {
      state.challengeUser = false;
    }

    function handleClubLink(clubRecord: ClubCaseModel): void {
      window.open(clubRecord.club.link);
    }

    const archiveChallengeList = computed(
      () => userInfo.value.challenges
        .filter((challenge: ChallengeResponseModel) => {
          if (!challenge.approved) {
            return false;
          }

          return !ChallengeHelper.isChallengeActive(challenge);
        }),
    );

    async function reloadAvailableForRegistration() {
      const tournamentApi = apiService.rpc.tournament;

      state.availableForRegistrationTournamentList = await tournamentApi
        .listAvailableForRegistration
        .call();
    }

    async function handleRegisteredOnTournament() {
      state.tournamentLoaded = false;

      await reloadAvailableForRegistration();

      state.tournamentLoaded = true;
    }

    async function handleOpenTournament() {
      const tournamentApi = apiService.rpc.tournament;

      state.tournamentList = await tournamentApi.listCurrent.call();

      await reloadAvailableForRegistration();

      state.tournamentLoaded = true;
    }

    return {
      state,
      ringInfoTitle,
      userInfo,
      archiveChallengeList,
      handleChallengeClick,
      handleChallengeUserClose,
      handleClubLink,
      handleOpenTournament,
      handleRegisteredOnTournament,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.italic {
  font-style: italic;
}

.super {
  vertical-align: super;
}

.pt5 {
  padding-top: 10px;
}

.p0 {
  padding: 0;
}

.club-header {
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
}

.challenge-button {
  display: block;
  width: 100%;
  color: #1677ff;
  border: 1px solid #1677ff;
  border-radius: 8px;
  padding: 10px 0;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
  }
}

.user-account-page {
  padding: 20px 10px;
  width: 320px;
  margin: 0 auto;

  &__header {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto 20px;
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: 0 auto;
  }
}

.collapse {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__challenge-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }

  &__challenge-list-item {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  &__challenge-list-item-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}
</style>
